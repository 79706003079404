.table__test3 {
  border-collapse: collapse;

  border-radius: 5px;
  border-radius: 5px;
  outline: 1.5px solid rgb(88, 87, 87);
  width: 95%;
}

.table__test3 thead {
  border-radius: 5px;
  outline: 1.5px solid rgb(88, 87, 87);
  width: 97%;
  background: #002d62;

  color: white;
  font-weight: 300;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 100;
}
.to__center__main {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.table__test3 > tbody > tr > td {
  border-bottom: 1px solid gray;
  padding: 10px 5px;
  font-size: small;
}
/* Regular CSS classes equivalent to Tailwind CSS classes */
.custom-container {
  margin: 0 8px; /* Equivalent to mx-8 */
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}

.custom-item {
  display: flex;
  align-items: center;
  margin: 0px 20px;
  margin-bottom: 3px; /* Equivalent to my-3 */
}

.custom-item h2 {
  margin-right: 8px; /* Adjust margin between elements as needed */
}

.item_section__filter {
  display: flex;
  margin: 5px 30px;
  gap: 15px;
  align-items: center;
}
.input__and__mapped__values {
  display: flex;
  margin: 5px 20px;
}
.input__and__mapped__values > div {
  display: flex;
  align-items: center;
}
.input__and__mapped__values > div:first-child {
  display: flex;
  width: 300px;
}
.input__and__mapped__values > div:first-child > p:first-child {
  width: 150px;
}
.input__and__mapped__values > div:nth-child(2) > p:nth-child(1) {
  width: 100px;
}
.input__and__mapped__values > div:nth-child(3) > p:nth-child(1) {
  width: 100px;
}
.icon__rotate {
  font-size: 60px;
  margin: 20px;

  transform: rotateY(180deg) rotate(360deg);

  animation: rotate 2s linear infinite; /* Adjust duration and timing function as needed */
}

@keyframes rotateMirror {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon__zoom__in__out {
  font-size: 60px;
  margin: 20px;

  animation: zoomInOut 1s ease-in-out infinite alternate; /* Adjust duration and timing function as needed */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); /* Adjust the scale factor for the zoom effect */
  }
}
.second__col__client h2 {
  width: 265px;
}
.first__col__client h2 {
  width: 265px;
}

@media (max-width: 768px) {
  .custom-container {
    display: block;
    margin: 0 1px; /* Equivalent to md:mx-1 */
    gap: 5px; /* Equivalent to md:gap-5 */
  }

  .custom-item {
    display: block; /* Equivalent to md:block */
    margin-bottom: 0; /* Reset margin bottom */
  }
  .item_section__filter {
    display: block;
  }
  .to__center__main {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    overflow: auto;
  }

  .input__and__mapped__values {
    display: block;
    width: 300px;
  }
  .input__and__mapped__values > div:first-child > p:first-child {
    width: 150px;
  }
  .input__and__mapped__values > div:nth-child(2) > p:nth-child(1) {
    width: 150px;
  }
  .input__and__mapped__values > div:nth-child(3) > p:nth-child(1) {
    width: 150px;
  }
  .above__90days {
    width: 150px;
  }
  .second__col__client h3 {
    width: auto;
  }
  .first__col__client h3 {
    width: auto;
  }
  .second__col__client div {
    display: block !important;
  }
  .first__col__client div {
    display: block !important;
  }
}
