.Logout__btn {
  padding: 8px 20px;
  background-color: var(--bg-color);
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px;
  color: #fff;
  border: none;
}
