.toalignaside {
  display: flex;
  width: 100%;
  padding: 0px 100px;
}
.footerstyle {
  margin-top: 40vh;
  background: rgb(226, 231, 232);
}

.aside1 {
  margin: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.aside2 {
  margin: 10px;
  width: 100%;

  margin-bottom: 10px;
}
.btnsfooter {
  width: 100px;
  background: rgb(51, 49, 167);
  color: white;
  padding: 14px 10px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  margin-left: 25px;
  cursor: pointer;
  transition: 1.3s;
}
.forbtns {
  display: flex;
  justify-content: space-around;
}
.btnsfooter:hover {
  background: rgb(5, 4, 84);
}
@media only screen and (max-width: 600px) {
  .toalignaside {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    padding: 0px 20px;
  }
  .aside1 {
    margin: 10px;
    width: 100%;
    margin-bottom: 0px;
  }
  .aside2 {
    margin: 10px;
    width: 100%;
    margin-top: 0px;
  }
}
