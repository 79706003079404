.elctric__page input {
  border: solid 1px;
  border-radius: 4px;
}
.button-50 {
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  background: #002d62;
  border-radius: 4px;
  border-width: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: JetBrains Mono, monospace;
  font-size: 18px;
  height: 30px;
  justify-content: center;
  margin: 5px;
  padding: 0 15px;
  width: auto;
}
.button-50 {
  height: 40px;
  background: #002d62;
  line-height: 1;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}
.for__submit__driver div {
  display: flex;
  gap: 2px;
}
.for__submit__driver div h3 {
  display: flex;
  gap: 2px;
  color: red;
}
