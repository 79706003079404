.registration-form-container {
  max-width: 550px;
  margin: 0px auto;
  padding: 20px;
  border-radius: 7px;
  background-color: #d0daff;
  border-style: ridge;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  box-shadow: 0px 13px 18px rgb(218 231 241);
  text-align: center;
}

.form {
  margin-top: 30px;
}

.h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  display: block;
  padding: 4px;
  font-size: medium;
}

.expiry_date {
  width: 188px;
}

.port_pass {
  border: none;
  border-bottom: 1px solid;
}

.center__no__data {
  display: flex;
}

.label > input {
  border: none;
  border-bottom: 1px solid;
  height: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 210px;
  border-radius: 4px;
}

.input_field:disabled {
  background-color: #fff;
}

.Note {
  padding: 5px;
  min-width: 240px;
  max-width: 240px;
  min-height: 103px;
  max-height: 103px;
}

.button[type="submit"] {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #090841 !important;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.nxt {
  display: flex;
  justify-content: space-between;
}

.nxt1 {
  display: flex;
  justify-content: end;
  padding: -19px;
}

.Btn {
  align-items: center;
  background: #8d9598;
  border: 0 solid #e2e8f0;
  box-sizing: border-box;
  color: #171616;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 3px;
  justify-content: center;
  overflow-wrap: break-word;
  padding: 17px;
  text-decoration: none;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button[type="submit"]:hover {
  background-color: #513dc0;
}

.registration_employee {
  display: flex;
  justify-content: space-between;
}

.img {
  clip-path: circle(50%);
  height: 134px;
}

.profile_pic {
  width: 130px;
  height: 130px;
}

.large_image {
  height: 200px;
}

.file-upload {
  display: none;
}

.circle {
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 129px;
  height: 128px;
  border: 2px solid rgb(15 14 14 / 20%);
  position: absolute;
  position: relative;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 15px;
}

img {
  max-width: 100%;
  height: auto;
}

.p-image {
  position: absolute;
  top: 167px;
  left: 90px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.upload-button {
  font-size: 1.2em;
}

.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}

.Detials > div > input {
  border: 1px solid;
  width: 235px;
  border-radius: 4px;
  height: 28px;
}

.Detials > div {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
}

.Detials > div > label {
  width: 200px;
  text-align: left;
}

.Detials > div > label::after {
  content: ":";
  width: 200px;
}

.row {
  text-align: -webkit-center;
}

.Detials {
  margin-top: 10px;
}

.radio {
  width: 20px;
}

.radio_btn {
  display: flex;
  gap: 12px;
  width: 165px;
  margin-right: 78px;
}

.Info > div {
  display: flex;
  padding: 10px;
  justify-content: center;
}

.input_field {
  border: 1px solid;
  width: 239px;
  border-radius: 4px;
  height: 28px;
}

.Info > div > .label {
  width: 198px;
  text-align: left;
}

.Info > div > .label::after {
  width: 198px;
  content: ":";
}

.portpass {
  display: flex;
}

.portDetails {
  display: flex;
}

.portDetails > .label {
  width: 200px;
}

.portDetails > .label::after {
  content: ":";
}

.Pass_port_no {
  display: flex;
  padding: 10px;
}

.Pass_port_no > label {
  width: 200px;
}

.Pass_port_no > input {
  border: 1px solid;
}

.upload_file_for_register > label {
  width: 200px;
}

.upload_file_for_register > label ::after {
  content: ":";
}
.upload__file::after {
  content: "" !important;
}

.camera_btn {
  background-color: var(--bg-color);
  border: none;
  color: white;
  width: 36px;
  border-radius: 3px;
  font-size: 17px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 15px 0px;
}

.Proceed {
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  background-color: #79c0e4;

  cursor: pointer;

  font-size: 15px;

  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;

  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;

  box-shadow: 0px 0px 0 0, #000 1px 2px 0 0px;
}

.retake {
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  background-color: #79c0e4;

  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;
  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;
  box-shadow: 0px 0px 0 0, #000 1px 2px 0 0px;
}

.Capture {
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  background-color: var(--bg-color);
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;
  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
  width: 150px;
}

.camera_btn2 {
  display: flex;
  gap: 20px;
}

.Pass_port_no .upload_file {
  width: 205px;
}

.Preview {
  padding: 10px 20px;
  background-color: #002d62;
  color: white;
  border-radius: 5px;
}

.preview_btn {
  padding-left: 25px;
}

.upload_file_for_register {
  display: flex;
  padding: 10px;
}

.camera__align {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin: 30px;
}

.new_view {
  margin-right: 35px !important;
  margin-left: 30px !important;
}

@media only screen and (max-width: 600px) {
  .camera__icon {
    display: none;
  }

  .textarea {
    display: flex;
    flex-direction: column;
  }

  .registration-form-container {
    width: 98%;
    overflow-x: scroll;
    padding: 0px;
  }

  .form {
    padding: 20px;
  }

  .camera_upload {
    gap: 9px;
  }

  .input_field {
    border: none;
    width: 300px;
    border-radius: 4px;
  }

  .Detials > div > input {
    border: none;
    width: 300px;
    border-radius: 4px;
    display: block;
  }

  .Note {
    border: 0.5px solid;

    min-width: 98%;
    max-width: 98%;
    min-height: 103px;
    max-height: 103px;
    display: block;
  }

  .nxt {
    padding: 20px;
  }

  .Detials > div > label {
    width: 200px;
    text-align: left;
  }

  .Info > div > .label {
    width: 198px;
    text-align: left;
  }

  .upload_file_for_register > label {
    width: 200px;
    text-align: left;
  }
}

/* === HEADING STYLE #3 === */
.header__of__main h1 {
  display: inline-block;
  font-family: "josefin sans";
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  color: var(--bg-color);
  text-transform: uppercase;
  margin-left: 20px;
}

.header__of__main h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: var(--bg-color);
}

.header__of__main h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;

  background-color: var(--bg-color);
}

@media only screen and (max-width: 750px) {
  .header__of__main h1 {
    font-size: 24px;
    margin-left: 0px;
  }

  .Detials > div,
  .Info > div {
    display: block;
  }
}
.input__box input[type="text"] {
  padding: 5px;
  width: 300px;
}
.input__box input[type="email"] {
  padding: 5px;
  width: 300px;
}
.input__box input[type="number"] {
  padding: 5px;
  width: 300px;
}
.input__box input[type="date"] {
  padding: 5px;
  width: 300px;
}
.textarea_NOTES textarea {
  border: 1px solid;
}
