.headers_details > aside {
  display: flex;
  text-transform: uppercase !important;
  width: 48%;
  gap: 5px;
}
.headers_details > aside > h1 {
  width: 230px;
}
.table__size__reducer th,
.table__size__reducer td {
  padding: 5px 6px !important;
  font-size: 11px !important;
}
@media screen and (max-width: 768px) {
  .headers_details > aside {
    display: block;
    text-transform: uppercase !important;
    width: 100%;
    gap: 5px;
  }
}
