.center-container_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh;  */
  margin-top: 30px;
}
.form-container_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  /* padding: 0px 40px 0px 40px; */
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}
.image-container {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
}
.main_map {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 10px;
  background: #ffffff;
}
.service_multi {
  width: 95%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.desc_width {
  /* height: 50%; */
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 10px;
  /* margin-left: 19px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.width_p {
  width: 45%;
}
.aside__new__garage {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
/* .aside__new__new {
  width: 47%;
} */
.second_map {
  width: 45%;
  /* height: fit-content; */
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 10px;
  margin-left: 27px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/* .aside__new__pic > div {
  width: 45%;
  display: flex;
  align-items: center;

  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */
.header__of__main h1 {
  color: var(--bg-color);
  display: inline-block;
  font-family: josefin sans;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  margin-left: 20px;
  padding-bottom: 5px;
  position: relative;
  text-transform: uppercase;
}
/*  */
.aside__new__garage > div {
  display: flex;
  align-items: center;

  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.PropertyTypeMaster__width > div {
  width: 42%;
}
.UnitTypeMaster__width > div {
  width: 45%;
  margin: 5px;
}
.PropertyUnitLeases__width > div {
  width: 45%;
  margin: 5px;
}
.UnitMaster__width > div {
  width: 43%;
  margin: 5px;
}
.DocumentMaster__width > div {
  width: 43%;
  margin: 5px;
}
.ProjectMaster__width > div {
  width: 45%;
  margin: 5px;
}
.PropertyUnitLeases__width > div > div {
  width: 300px;
}
.UnitMaster__width > div > div {
  width: 300px;
}
.ProjectMaster__width > div > div {
  width: 300px;
}
.UnitTypeMaster__width > div > div {
  width: 300px;
}

/*  */
.PropertyUnitLeases__width:last-child > div {
  display: flex;
  justify-content: space-between;
}
.UnitMaster__width:last-child > div {
  display: flex;
  justify-content: space-between;
}
.ProjectMaster__width:last-child > div {
  display: flex;
  justify-content: space-between;
}

.aside__new__garage > div:first-child > p {
  width: 200px;
}
.aside__new__garage > div > div > input {
  height: 35px;
  width: 300px;
}
.aside__new__garage > div > div > select {
  height: 35px;
  width: 300px;
}
.select__newGarage {
  height: 35px;
  width: 300px;
}
.aside__new__garage > div:last-child > p {
  width: 200px;
}
.input_1 {
  border: 1px solid;
}
.geo__Loaction__new {
  width: 100%;
  display: flex;
  align-items: center;
  display: block;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 10px;
  /* margin-left: 19px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/*  */
.form_info {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95%;
  background: aliceblue;
  border: 1px solid;
  border-radius: 10px;
}
.aside__new__garage > div label {
  font-weight: 500;
}
#imagePreview {
  max-width: 100%;
  max-height: 300px; /* Adjust as needed */
  margin-top: 10px;
}
#imagePreview_2 {
  max-width: 100%;
  max-height: 300px; /* Adjust as needed */
  margin-top: 10px;
}
.select__country {
  width: 280px;
  height: 37px;
}
.align__ofrm__data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section__newgarage {
  width: 100%;
  border: 10px;
  border-radius: 4px;

  gap: 156px;
}
.table_td_info {
  /* display: flex; */
  align-items: center;
  gap: 10px;
}
/* test1 */
.table td {
  /* padding: 10px; */
  vertical-align: top;
  font-size: 11px;
}
.table label {
  font-size: medium;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 5px;
  display: inline-block;
  text-transform: uppercase;
  width: 200px;
  font-size: 11px;
}
.search__tag {
  margin-left: 20px;
}
/* 
.table tr td div {
  margin-top: 5px;
} */
.table label::after {
  content: ":";
  font-size: medium;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* text-transform: uppercase */
}
.table tr td input {
  height: 30px;
  margin: 5px;

  text-transform: uppercase;
  font-size: 11px;
}
/* test2 */
.table tr td input[type="file"] {
  border: none;
  font-size: 11px;
}
.image__style_1 {
  max-width: 100%;

  height: 92vh;
  width: auto;
}
.country_input {
  display: flex;
  width: 100%;
}
.strong {
  color: red;
}
.table tr td select {
  height: 30px;
  margin: 5px;
  padding-left: 5px;
  width: 170px;
  font-size: 11px;
}
.btn3 {
  text-align: right;
  display: flex;
  align-items: end;
  margin-bottom: 10px;
}
.garage_id_ {
  text-transform: uppercase;
  font-size: 16px;
}
.information {
  max-width: 300px;
  min-width: 300px;
  max-height: 114px;
  min-height: 114px;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0px;
  /* font-family: "Times New Roman", Times, serif; */
}
.search_field {
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.filter_garage {
  height: 30px;
  width: 300px;
  border-radius: 6px 1px solid;
}
.save_btn {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.image__style__new___garage {
  height: auto;
  max-width: 100%;
}

/* test1 */
.buttons__reset__submit {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 40px;
  justify-content: space-between;
}
.aside__new__garage_ > div:first-child > p {
  width: 178px;
}
.aside__new__garage_ {
  margin-left: 20px;
}
.aside_new {
  display: block;
  /* justify-content: ; */
}
.aside__new__garage_1 > aside {
  width: 45%;
  display: flex;
  align-items: center;

  background-color: #fff;
  border-radius: 5px;
  padding: 12px 20px;
  margin: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.fixTableHead {
  overflow-y: auto;
  /* height: 110px; */
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
.service_detials {
  align-items: center;
  display: flex;
  gap: 30px;
  width: 100%;
  margin-top: 20px;
  z-index: 0;
}
.table_1 {
  border-collapse: collapse;
  border-radius: 4px;
  outline: 1.5px solid #585757;
  width: 95%;
}
.table_1 thead {
  z-index: 0;
}
.button {
  background-color: #002d62;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin: 0px 10px;
  padding: 0px 20px;
  height: 35px;
}
.table_1 thead,
.table_1_ thead {
  background: #002d62;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  height: 50px;
  outline: 1.5px solid #585757;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 97%;
}

.table_1 tr td {
  border-bottom: 1px solid gray;
  font-size: 12px;
  padding: 10px 13px;
}
.service_detials {
  padding: 5px;
  overflow: auto;
  justify-content: center;
}
.name__head__small__screen {
  display: none;
}
.navbar-links {
  gap: 20px;
}
.to_center_property_lease {
  justify-content: flex-start;
}
.inpection__master > div {
  margin: 10px 0px;
}
.table__wifth__small {
  width: 60% !important;
}
.startand__enddate__lease {
  display: flex;
  margin: 5px 30px;
  gap: 20px;
}
.startand__enddate__lease > div {
  display: flex;
  gap: 10px;
}
.searc__lease {
  display: flex;
  margin: 5px 30px;
  gap: 20px;
  font-weight: 600;
  font-size: 18px;
}
.search__lease {
  width: 250px;
  font-weight: 600;
  font-size: 20px;
  height: 30px;
}
.search__icon__lease {
  position: relative;
  right: 40px;
  top: 8px;
}
.lease__bothfilte {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.inspection_details {
  background: aliceblue;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  justify-content: start;
}
.inspection_details_1 {
  display: flex;
  margin-left: 20px;
}
.header_details {
  color: var(--bg-color);
  display: inline-block;
  font-family: josefin sans;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  /* margin-left: 20px; */
  padding-bottom: 5px;
  position: relative;
  text-transform: uppercase;
  padding: 4px;
}

.section__newgarage__ {
  position: sticky;
  top: 10px;
  background-color: #fff;
  border-radius: 5px;

  left: 0;
  margin: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.details__capture > div > p:first-child {
  width: 30px;
}
.garage_id_labe {
  text-transform: uppercase;
  font-size: 21px;
}

.main__style__home {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: space-between;
}
/* .section__newgarage {
  text-align: center;
} */

.details__ {
  margin-left: 20px;
  display: inline-block;
}

.garage_id_label {
  margin-bottom: 10px;
  display: flex;
}

.garage_id_label p {
  margin-right: 8px;
  /* Adjust the margin as needed */
}
.garage_id_label:nth-child(7) {
  margin-bottom: 20px; /* Adjust the gap size as needed */
}
.garage_id_label p:first-child::after {
  display: inline-block;
  margin-right: 5px; /* Add margin for spacing */
}
.garage_id_label p:first-child::after {
  display: inline-block;
  margin-left: 5px; /* Adjust the margin for spacing */
}
.section__newgarage__ {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.form-container_1_ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  /* padding: 0px 40px 0px 40px; */
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}
.service_detials__ {
  padding: 5px;
  /* width: 400px; */
  display: grid;
  grid-template-columns: auto;
  gap: 20px;
  align-items: center;
}
.iside__grid__postion {
  margin: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
}
.select__inspect {
  border-radius: 5px;
  height: 30px;
  padding-left: 3px;
  margin: 0px 10px;
}
.checkbox__inspect {
  border-radius: 5px;
  height: 30px;

  margin: 5px 0px;
}

.table_grid {
  display: block;
  align-items: top;
  width: 150px;
}
.tr_values td {
  text-align: center;
  align-items: center;
}
.tr_values select {
  border: 1px solid;
}
.tr_values input {
  height: 20px;
}
@media only screen and (max-width: 1030px) {
  .name__head__big__screen {
    display: none;
  }
  .name__head__small__screen {
    display: block;
    margin-left: 10px;
  }
  .navbar-links {
    justify-content: flex-start;
  }
  .UnitTypeMaster__width > div {
    width: 60%;
  }
  .PropertyTypeMaster__width > div {
    width: 66%;
  }
  .DocumentMaster__width > div {
    width: 73%;
    margin: 5px;
  }
  .aside__new__garage {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .UnitMaster__width > div {
    width: 76%;
    margin: 5px;
  }
  .UnitMaster__width {
    display: flex;
    gap: 0px;
  }
  .PropertyUnitLeases__width > div {
    width: 75%;
  }
  .service_detials {
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
  }
  .button {
    background-color: #002d62;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    margin: 0px 10px;
    padding: 0px 10px;
    height: 35px;
  }
  .PropertyUnitLeases__width:last-child > div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .UnitMaster__width:last-child > div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .ProjectMaster__width:last-child > div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .ProjectMaster__width > div {
    width: 75%;
    margin: 5px;
  }
  .ProjectMaster__width > div > div {
    width: 100%;
  }
  .inpection__master > div {
    display: block;
  }
  .filter__no__search {
    display: block;
  }
  .search__propert__lease {
    display: none;
  }
  .startand__enddate__lease {
    display: block;
    margin: 5px 30px;
  }
  .startand__enddate__lease > div {
    display: flex;
    margin: 10px 0px;
    gap: 10px;
  }
  .startand__enddate__lease > div p {
    width: 100px;
  }
  .searc__lease {
    display: block;
    margin: 5px 30px;
    gap: 20px;
  }
  .search__icon__lease {
    display: none;
  }
  .lease__bothfilte {
    display: block;
  }
}
@media only screen and (max-width: 720px) {
  .desc_width {
    display: block;
    width: 96%;
    margin-left: 0%;
  }
  .width_p {
    width: fit-content;
  }
  .section__newgarage {
    padding: 5px;
  }
  .table tr {
    display: flex;
    flex-direction: column;
  }

  .garage_id_ {
    text-transform: uppercase;
  }
  .table {
    flex-direction: column;
    gap: 0px;
  }
  .to_center {
    width: 50%;
  }
  .div_section {
    flex-direction: column-reverse !important;
  }
  .country_input {
    display: flex;
    flex-direction: column;
  }
  .center-container_1 {
    display: flex;
    justify-content: center;
    align-items: center;

    /* height: 133vh;  */
  }
  .ProjectMaster__width:last-child > div {
    display: block;

    flex-direction: column;
  }
  .align__ofrm__data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-container_1 {
    width: 95%;
  }
  .align__ofrm__data > p {
    margin: 10px 0px;
  }
  .table td {
    padding: 0px 10px;
  }
  .aside__new__garage {
    display: flex;
    flex-direction: column;
  }
  .aside__new__garage_ {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  .aside__new__garage > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 5px;
    padding: 12px 10px;
    margin: 5px 0px;
  }
  .aside__new__garage_ > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 5px;
    padding: 12px 10px;
    margin: 10px 0px;
  }
  .aside__new__garage > div:first-child > p {
    width: 200px;
  }
  .aside__new__garage > div > div > input {
    height: 30px;
    width: 250px;
    margin: 5px;
  }
  .aside__new__garage > div > div > select {
    height: 30px;
    width: 250px;
    margin: 5px;
  }
  .select__newGarage {
    height: 30px;
    width: 250px;
    margin: 5px;
  }
  .aside__new__garage > div:last-child > p {
    width: 200px;
  }
  .geo__Loaction__new {
    width: 96%;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    margin-left: 0%;
  }
  .aside__new__new {
    width: 100%;
  }
  .disc__and__pic {
    width: 100%;
  }
  .aside__new__new > div {
    margin: 10px 0px;
  }
  /* .aside__new__pic > div {
    margin: 0px;
  } */
  .textmax__width {
    max-width: 300px;

    min-width: 300px;
    min-height: 100px;
    max-height: 100px;
  }
  .information {
    max-width: 210px;
    min-width: 210px;
    max-height: 114px;
    min-height: 114px;
    border-radius: 4px;
    padding: 10px;
  }
  .buttons__reset__submit {
    padding: 0px 20px;
  }
  .aside__new__garage_ > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 5px;
    padding: 12px 10px;
    margin: 10px 0px;
  }
  .second_map {
    width: 93%;
    margin-left: 10px;
  }
  .header__of__main h1 {
    color: var(--bg-color);
    display: inline-block;
    font-family: josefin sans;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5em;
    margin-left: 10px;
    padding-bottom: 0px;
    position: relative;
    text-transform: uppercase;
  }
  .table__wifth__small {
    width: 100% !important;
  }
}

/*  */
.fpo {
  position: relative;
  margin: 25% auto;
  display: block;
}
.pulsate {
  background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}
.block {
  display: block;
  width: 271px;
  height: 16px;
  color: black;
}

.block2 {
  width: 78px;
  height: 8px;
  margin-bottom: 8px;
}
.block3 {
  width: 131px;
  height: 8px;
  margin-bottom: 16px;
}
.circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  float: right;
}
#card {
  box-sizing: border-box;
  width: 335px;
  background: #fff;
  position: relative;
  margin: auto;
  top: 25%;
}
.card-image {
  box-sizing: border-box;
  display: block;
  width: 335px;
  height: 120px;
  background: #fafafa;
  padding: 16px;
}
.card-content {
  clear: both;
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
}
#imagePreview {
  max-width: 100%;
  max-height: 300px; /* Adjust as needed */
  margin-top: 10px;
}
#imagePreview_2 {
  max-width: 100%;
  max-height: 300px; /* Adjust as needed */
  margin-top: 10px;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* styles.css */

/* Apply styles to make a grid container filling the screen */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Display 2 columns */
  gap: 0; /* Remove gaps between grid items */
  height: 100vh; /* Fill entire vertical height of the screen */
}

/* Limiting 2 divs (tables) per column */
.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000; /* Add borders for visualization */
  grid-column: span 1; /* Span 1 column */
}

/* Adjust height for specific tables */
.table4 {
  height: 100%; /* Full height for table 4 */
}
.table5,
.table6 {
  height: 50%; /* Half height for tables 5 and 6 */
}
.for__table__width__full {
  width: 100% !important;
}
.css-art2ul-ValueContainer2 {
  padding: 0px !important;
}
.css-1xc3v61-indicatorContainer {
  position: absolute !important;
  right: 0 !important;
}
.css-15lsz6c-indicatorContainer {
  padding: 0px !important;
}
.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}
.zindex__thead {
  z-index: 100 !important;
}
