@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* @media (max-width: 720px){
  input,select  {
    background-color: white;
}
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --text-color: #fff;
  --bg-color: #002d62;
  --font--weight: 500;
}

input {
  padding-left: 5px;
  border: 1px solid;
  border-radius: 3px;
}
textarea {
  padding: 5px;
}
/* tr:hover {
  background-color: #f7f3f3;
} */
html {
  scroll-behavior: smooth;
}
.css-art2ul-ValueContainer2 {
  padding: 0px !important;
}
.css-1xc3v61-indicatorContainer {
  position: absolute !important;
  right: 0 !important;
}
.css-15lsz6c-indicatorContainer {
  padding: 0px !important;
}
.button_full_width {
  width: 100%;
}
.button_full_width div {
  display: flex;
  justify-content: space-between;
}
.button_full_width div button {
  width: 100%;
}
.active__tab {
  border: 1px solid;
  border-radius: 5px 5px 0px 0px;
  border-bottom: none;
}
.button_style__tab {
  padding: 3px;
  background-color: #444482;
  color: white;
  width: "110px";
  border-radius: 3px;
  border: 1px solid white;

  height: fit-content;
}
.active__section {
  /* padding: 5px;
  margin: 8px;
  border: 1px solid; */
  border-top: none;
  margin-top: 0px;
}
