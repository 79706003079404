.container {
  max-width: 800px;
  background: #fff;
  width: 800px;
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.container .text {
  text-align: center;
  font-size: 41px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  background: -webkit-linear-gradient(
    right,
    #56d8e4,
    #9f01ea,
    #56d8e4,
    #9f01ea
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container form {
  padding: 30px 0 0 0;
}
.container form .form-row {
  display: flex;
  margin: 32px 0;
}
form .form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea {
  height: 70px;
}
.input-data input,
.textarea textarea {
  margin-top: 15px;
  display: block;
  width: 500px;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.input-data input:focus ~ label,
.textarea textarea:focus ~ label,
.input-data input:valid ~ label,
.textarea textarea:valid ~ label {
  transform: translateY(-30px);
  font-size: 14px;
  color: #3498db;
}
.textarea textarea {
  resize: none;
  padding-top: 10px;
}
.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 15px;
  transition: all 0.3s ease;
  color: grey;
}
.textarea label {
  width: 100%;
  bottom: 40px;
  background: #fff;
}
.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 500px;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before {
  transform: scale(1);
}
.submit-btn .input-data {
  overflow: hidden;
  height: 45px !important;
  width: 25% !important;
}
.submit-btn .input-data .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(
    right,
    #56d8e4,
    #9f01ea,
    #56d8e4,
    #9f01ea
  );
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner {
  left: 0;
}
.submit-btn .input-data input {
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.savebutton {
  display: flex;
  justify-content: flex-end;
  margin: 10px 5px;
  margin-top: 15px;
}

.inputttt {
  border: 1px solid black;
  color: red;
}

.buttonunit {
  background-color: #002d62;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  /* margin: 0 100px; */
  padding: 0px 20px;
  height: 35px;
}

.buttonunit1 {
  background-color: #002d62;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin-right: 320px;
  padding: 10px 20px;
}

.buttonunit2 {
  background-color: #002d62;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin-right: 30px;
  padding: 0px 20px;
  height: 35px;
}

.searchhhhhh {
  display: flex;
  margin-left: 30px;

  margin: 10px 35px;
  gap: 10px;
  font-size: 18px;
}
.searchhhhhh input {
  width: 300px;
  padding-right: 20px;
}
.search__icon {
  position: relative;
  right: 30px;
  background-color: #fff;
  height: 22px;
  top: 3px;
}
.service_details {
  margin-top: 20px;
  width: 50%;
  margin-left: 300px;
}
@media (max-width: 700px) {
  .container .text {
    font-size: 30px;
  }
  .service_details {
    margin-top: 30px;
    width: 50%;
    margin-left: 20px;
  }
  .container form {
    padding: 10px 0 0 0;
  }
  .container form .form-row {
    display: block;
  }
  form .form-row .input-data {
    margin: 35px 0 !important;
  }
  .submit-btn .input-data {
    width: 40% !important;
  }
}
